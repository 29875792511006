import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

// export const getServiceDetail = createAsyncThunk(
//     "ServiceDetail/getServiceDetail",
//     async () => {
//       const response = await axios.get(
//         `https://server.myoffice.ae/wp-json/api/v4/getservicess`
//       );
//       const data = await response.data;
//       return data;
//     }
//   );

//   const ServiceDetailSlice = createSlice({
//     name: "ServiceDetail",
//     initialState: {
//       page: [],
//       status: null,
//     },
//     extraReducers: {
//       [getServiceDetail.pending]: (state, action) => {
//         state.status = "loading";
//       },
//       [getServiceDetail.fulfilled]: (state, { payload }) => {
//         state.page = payload;
//         state.status = "success";
//       },
//       [getServiceDetail.rejected]: (state, action) => {
//         state.status = "failed";
//       },
//     },
//   });
//   export default ServiceDetailSlice.reducer;


export const servicesSlice = createSlice({
    name: "services",
    initialState: {
      servicesdata: [],
      isServicesLoading: false,
      errorMessageServices:'',
      isServicesLoading: false,
    },
    reducers: {
      startServicesLoading: (state) => {
        state.isServicesLoading = true;
      },
      initializeServices: (state, action) => {
        state.servicesdata = action.payload;
        state.isServicesLoading = false;
      },
      errorgettingServices : (state)=>{
        state.errorMessageServices="Could Not Get Data , Please Try Again Later"
      },
    },
  });

  export const { startServicesLoading, initializeServices, errorgettingServices} =
  servicesSlice.actions;

export default servicesSlice.reducer;
  


export const getServices =({slug}) =>async (dispatch) =>{
  console.log(slug)
  try {
    dispatch(startServicesLoading())
    axios.get(`https://server.myoffice.ae/wp-json/wp/v2/services?slug=` + slug).then(({data}) => {
         dispatch(initializeServices({data}));
        //  console.log({data});
    });
  } catch (e){
    dispatch(errorgettingServices())
    return
  }
}