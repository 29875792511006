import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getContactForm = createAsyncThunk(
  "ContactForm/getContactForm",
  async ({ id }) => {
    const response = await axios.get(
      "https://www.myoffice.ae/backend/wp-json/wp/v2/wpcf7_contact_form/7/?"
    );
    const data = await response.data;
    return data;
  }
);

const ContactFormSlice = createSlice({
  name: "ContactForm",
  initialState: {
    page: [],
    status: null,
  },
  extraReducers: {
    [getContactForm.pending]: (state, action) => {
      state.status = "loading";
    },
    [getContactForm.fulfilled]: (state, { payload }) => {
      state.page = payload;
      state.status = "success";
    },
    [getContactForm.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default ContactFormSlice.reducer;