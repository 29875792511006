import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getFranchisepage = createAsyncThunk(
  "Franchisepage/getFranchisepage",
  async ({ id }) => {
    const response = await axios.get(
      "https://server.myoffice.ae/wp-json/api/v4/getpartner"
    );
    const data = await response.data;
    return data;
  }
);

const FranchisepageSlice = createSlice({
  name: "Franchisepage",
  initialState: {
    page: [],
    status: null,
  },
  extraReducers: {
    [getFranchisepage.pending]: (state, action) => {
      state.status = "loading";
    },
    [getFranchisepage.fulfilled]: (state, { payload }) => {
      state.page = payload;
      state.status = "success";
    },
    [getFranchisepage.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default FranchisepageSlice.reducer;