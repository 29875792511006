import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getDubaiMarinapage = createAsyncThunk(
  "DubaiMarinapage/getDubaiMarinapage",
  async ({ id }) => {
    const response = await axios.get(
      "https://server.myoffice.ae/wp-json/api/v4/getmarina???????????"
    );
    const data = await response.data;
    return data;
  }
);

const DubaiMarinapageSlice = createSlice({
  name: "DubaiMarinapage",
  initialState: {
    page: [],
    status: null,
  },
  extraReducers: {
    [getDubaiMarinapage.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDubaiMarinapage.fulfilled]: (state, { payload }) => {
      state.page = payload;
      state.status = "success";
    },
    [getDubaiMarinapage.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default DubaiMarinapageSlice.reducer;