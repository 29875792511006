import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getBlogpage = createAsyncThunk(
  "blogpage/getBlogpage",
  async ({ id }) => {
    const response = await axios.get(
      "https://server.myoffice.ae/wp-json/wp/v2/pages/458?"
    );
    const data = await response.data;
    return data;
  }
);

const BlogpageSlice = createSlice({
  name: "blogpage",
  initialState: {
    page: [],
    status: null,
  },
  extraReducers: {
    [getBlogpage.pending]: (state, action) => {
      state.status = "loading";
    },
    [getBlogpage.fulfilled]: (state, { payload }) => {
      state.page = payload;
      state.status = "success";
    },
    [getBlogpage.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default BlogpageSlice.reducer;