import './App.scss';
import Routes from './routes';
import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.js';
import 'aos/dist/aos.css';
import store from './redux/store';
import { Provider } from 'react-redux';

function App() {
  return (
    <div className="App">
      <Router>
        <Provider store={store}>
          <Routes/>
        </Provider>
      </Router>
    </div>
  );
}

export default App;
