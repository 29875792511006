import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getFAQspage = createAsyncThunk(
  "FAQspage/getFAQspage",
  async ({ id }) => {
    const response = await axios.get(
      "https://server.myoffice.ae/wp-json/api/v4/getfaq"
    );
    const data = await response.data;
    return data;
  }
);

const FAQspageSlice = createSlice({
  name: "FAQspage",
  initialState: {
    page: [],
    status: null,
  },
  extraReducers: {
    [getFAQspage.pending]: (state, action) => {
      state.status = "loading";
    },
    [getFAQspage.fulfilled]: (state, { payload }) => {
      state.page = payload;
      state.status = "success";
    },
    [getFAQspage.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default FAQspageSlice.reducer;