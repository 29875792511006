import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getHeaderData = createAsyncThunk(
  "HeaderData/getHeaderData",
  async () => {
    const response = await axios.get(
      "https://server.myoffice.ae/wp-json/api/v4/getheader"
    );
    const data = await response.data;
    // console.log(data);
    return data;
  }
);

const HeaderDataSlice = createSlice({
  name: "HeaderData",
  initialState: {
    header: [],
    status: null,
  },
  extraReducers: {
    [getHeaderData.pending]: (state, action) => {
      state.status = "loading";
    },
    [getHeaderData.fulfilled]: (state, { payload }) => {
      state.header = payload;
      state.status = "success";
    },
    [getHeaderData.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});
export default HeaderDataSlice.reducer;