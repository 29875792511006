import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// export const getBlogDetail = createAsyncThunk(
//     "BlogDetail/getBlogDetail",
//     async ({ slug }) => {
//       const response = await axios.get(
//         // `https://www.myoffice.ae/backend/blog/wp-json/wp/v2/posts?_embed&per_page=100`
//         `https://www.myoffice.ae/backend/blog/wp-json/wp/v2/posts?slug=` + slug
//       );
//       const data = await response.data;
//       return data;
//     }
//   );

//   const BlogDetailSlice = createSlice({
//     name: "BlogDetail",
//     initialState: {
//       page: [],
//       status: null,
//     },
//     extraReducers: {
//       [getBlogDetail.pending]: (state, action) => {
//         state.status = "loading";
//       },
//       [getBlogDetail.fulfilled]: (state, { payload }) => {
//         state.page = payload;
//         state.status = "success";
//       },
//       [getBlogDetail.rejected]: (state, action) => {
//         state.status = "failed";
//       },
//     },
//   });
//   export default BlogDetailSlice.reducer;


const url = process.env.REACT_APP_WORDPRESS_URL;


export const homeSlice = createSlice({
    name: "home",
    initialState: {
      homedata: [],
      isHomeLoading: false,
      errorMessageHome:'',
      isBlogLoading: false,
    },
    reducers: {
      startHomeLoading: (state) => {
        state.isBlogLoading = true;
      },
      initializeHome: (state, action) => {
        state.homedata = action.payload;
        state.isBlogLoading = false;
      },
      errorgettingHome : (state)=>{
        state.errorMessageHome="Could Not Get Data , Please Try Again Later"
      },
    },
  });

  export const { startHomeLoading, initializeHome, errorgettingHome} =
  homeSlice.actions;

export default homeSlice.reducer;
  


export const getHome =({slug}) =>async (dispatch) =>{
  // console.log(slug)
  try {
    dispatch(startHomeLoading())
    axios.get(`https://server.myoffice.ae/blog/wp-json/wp/v2/posts?slug=` + slug).then(({data}) => {
         dispatch(initializeHome({data}));
         console.log({data});
    });
  } catch (e){
    dispatch(errorgettingHome())
    return
  }
}