import { configureStore } from "@reduxjs/toolkit";
import getHeaderReducer from "./HeaderRedux";
import getFooterReducer from "./FooterRedux";
import HomepageReducer from "./HomepageRedux";
import ServiceDetailReducer from "./ServiceDetailRedux";
import AboutpageReducer from "./AboutpageRedux";
import FAQspageReducer from "./FAQspageRedux";
import DubaiMarinapageReducer from "./DubaiMarinapageRedux";
import DubaiDowntownpageReducer from "./DubaiDowntownpageRedux";
import BlogpageReducer from "./BlogpageRedux";
import BloglistingReducer from "./BlogListRedux";
import BlogDetailReducer from "./BlogDetailRedux";
import ContactpageReducer from "./ContactpageRedux";
import FranchisepageReducer from "./FranchisepageRedux";
import ContactFormReducer from "./ContactFormRedux";
import HomepageRussianReducer from "./HomepageRussianRedux"

export default configureStore({
  reducer: {
    HeaderData: getHeaderReducer,
    FooterData: getFooterReducer,
    Homepage: HomepageReducer,
    services: ServiceDetailReducer,
    Aboutpage: AboutpageReducer,
    FAQspage: FAQspageReducer,
    Franchisepage: FranchisepageReducer,
    Contactpage: ContactpageReducer,
    DubaiMarinapage: DubaiMarinapageReducer,
    DubaiDowntownpage: DubaiDowntownpageReducer,
    Blogpage: BlogpageReducer,
    Bloglisting: BloglistingReducer,
    BlogDetail: BlogDetailReducer,
    home: BlogDetailReducer,
    ContactForm: ContactFormReducer,
    Homepagerussian:HomepageRussianReducer,
  },
});